import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';

export default styled.div<{ color?: string; size?: number }>`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 100%;
  opacity: 50%;
  filter: blur(60px);
  transform: scale(1.5);
  background-color: ${({color}) => color ? color : '#000'};
  ${({size}) => size && size > 0 ? `
    top: calc(50% - ${size / 2}px);
    bottom: unset;
    left: calc(50% - ${size / 2}px);
    right: unset;
    width: ${size}px;
    height: ${size}px;
  ` : ''};
  & ~ * {
    z-index: 1;
  }
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    opacity: 70%;
    transform: scale(1.3);
  }
`;
