export const LINK_CHROME_WEB_STORE =
  "https://chrome.google.com/webstore/detail/mapbhaebnddapnmifbbkgeedkeplgjmf";
export const LINK_GOOGLE_PLAY_STORE =
  "https://play.google.com/store/apps/details?id=com.pilab.biport";
export const LINK_BIFROST = "https://theBifrost.io/";
export const LINK_BIFI = "https://bifi.finance/";
export const LINK_BIFI_X = "https://x.bifi.finance/";
export const LINK_BFC_BOT = "https://bfcbot.com/";
export const LINK_SNS_FACEBOOK = "https://www.facebook.com/theBifrost.io/";
export const LINK_SNS_GITHUB = "https://github.com/bifrost-platform/";
export const LINK_SNS_INSTAGRAM = "https://twitter.com/BiportWallet/";
export const LINK_SNS_TWITTER = "https://twitter.com/BiportWallet/";
export const LINK_SNS_REDDIT = "https://www.reddit.com/r/bifrostnetwork/";
export const LINK_GUIDE = "https://docs.bifi.finance/biport-guide/";
export const LINK_EN_INTRODUCTION =
  "https://medium.com/bifrost/biport-mobile-closed-beta-test-renovation-and-chainrunner-integration-9fb1cda60b4c";
export const LINK_KO_INTRODUCTION =
  "https://bifrost.medium.com/79fd4fc36325?source=friends_link&sk=a7ea4eff6db79d3e9eaebf632ed2eead";
export const LINK_HELPCENTER =
  "https://pi-lab.atlassian.net/servicedesk/customer/portals";
export const LINK_EN_REGISTER = "https://forms.gle/9TPrXf1yNoWo42PS9";
export const LINK_KO_REGISTER = "https://forms.gle/fG2Tujg1qEJeewEU6";
export const LINK_LINKTREE = "https://linktr.ee/bifrost_network";
