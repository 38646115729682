import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import { AppSection, H1, Text } from 'styled-interfaces';
import ImageCrossChainSwap from 'assets/images/cross-chain-swap.png';
import ImageCrossChainBridge from 'assets/images/cross-chain-bridge.png';
import ImageCrossChainCrosschainswap from 'assets/images/cross-chain-crosschainswap.png';
import ScrollFire from 'components/ScrollFire';

const Content = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 20%;
  color: #fff5f5;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-right: 0;
  }
`;
const Title = styled(H1)<{ opacity?: boolean; }>`
  margin: 0px;
  font-size: 60px;
  color: #fff;
  ${props => props.opacity ? 'opacity: 0.6;' : ''}
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 24px;
    line-height: 35px;
  }
`;
const Description = styled(Text)`
  margin: 0px;
  padding: 40px 0px 80px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-top: 30px;
    padding-bottom: 40px;
    font-size: 15px;
    line-height: 25px;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 0px -45px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    flex-direction: column;
    align-items: center;
    margin: -15px 0px;
  }
`;
const ContentWrapper = styled.div`
  flex-grow: 0.3333;
  width: calc(33.33% - 90px);
  padding: 0px 45px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    flex-grow: unset;
    width: 70%;
    padding: 15px 0px;
    font-size: 20px;
  }
`;
const ContentImageWrapper = styled.div`
`;
const ContentImage = styled.img`
  width: 100%;
`;
const ContentTag = styled.div`
  padding-top: 36px;
  font-weight: 700;
  font-size: 36px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-top: 12px;
    font-size: 20px;
  }
`;

const CrossChain: React.FC<{ scrollY: number; }> = ({ scrollY }) => {
  return (
    <AppSection>
      <Content>
        <ScrollFire scrollY={scrollY}>
          <Title>DeFi made easy, all in one screen</Title>
        </ScrollFire>
        <ScrollFire scrollY={scrollY}>
          <Description>A powerful built-in crosschain swap, enabled by Bifrost.<br />Bridge, swap, and perform crosschain swap, all in one place. Receive updates on the trending tokens and instantly swap with Bifrost’s Crosschain Swap without ever exiting the app. Biport enables swaps with minimal transaction fees and has smart options that recommend efficient swap routes.</Description>
        </ScrollFire>
      </Content>
      <ContentContainer>
        <ContentWrapper>
          <ContentImageWrapper>
            <ScrollFire scrollY={scrollY}>
              <ContentImage src={ImageCrossChainSwap} />
            </ScrollFire>
          </ContentImageWrapper>
          <ContentTag>
            <ScrollFire scrollY={scrollY}>Swap</ScrollFire>
          </ContentTag>
        </ContentWrapper>
        <ContentWrapper>
          <ContentImageWrapper>
            <ScrollFire scrollY={scrollY} delay={0.1}>
              <ContentImage src={ImageCrossChainBridge} />
            </ScrollFire>
          </ContentImageWrapper>
          <ContentTag>
            <ScrollFire scrollY={scrollY} delay={0.1}>Bridge</ScrollFire>
          </ContentTag>
        </ContentWrapper>
        <ContentWrapper>
          <ContentImageWrapper>
            <ScrollFire scrollY={scrollY} delay={0.2}>
              <ContentImage src={ImageCrossChainCrosschainswap} />
            </ScrollFire>
          </ContentImageWrapper>
          <ContentTag>
            <ScrollFire scrollY={scrollY} delay={0.2}>CrosschainSwap</ScrollFire>
          </ContentTag>
        </ContentWrapper>
      </ContentContainer>
    </AppSection>
  );
};

export default CrossChain;
