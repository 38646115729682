import React from 'react';
import { AppContainer } from 'styled-interfaces';
import { Header, Main, OneScreen, NFT, CrossChain, Management, WrapUp } from './section-home';

const Home: React.FC<{ scrollY: number; }> = ({ scrollY }) => (
  <AppContainer>
    <Header />
    <Main />
    <OneScreen scrollY={scrollY} />
    <NFT scrollY={scrollY} />
    <CrossChain scrollY={scrollY} />
    <Management scrollY={scrollY} />
    <WrapUp scrollY={scrollY} />
  </AppContainer>
);

export default Home;
