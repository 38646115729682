import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';

export const APP_CONTAINER_MARGIN = 95;
export const APP_CONTAINER_MARGIN_MOBILE = 30;

export default styled.div`
  position: relative;
  margin-left: ${APP_CONTAINER_MARGIN}px;
  margin-right: ${APP_CONTAINER_MARGIN}px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-left: ${APP_CONTAINER_MARGIN_MOBILE}px;
    margin-right: ${APP_CONTAINER_MARGIN_MOBILE}px;
  }
`;
