import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { AppSection } from 'styled-interfaces';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import { LINK_CHROME_WEB_STORE, LINK_GOOGLE_PLAY_STORE } from 'configs/link';
import Btn from 'components/Btn';
import StartFire from 'components/StartFire';
import ImageDownloadPlaystore from 'assets/images/download-playstore.svg';
import ImageDownloadChrome from 'assets/images/download-chrome.svg';

const StyledAppSection = styled(AppSection)`
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 70px;
  padding-bottom: 0px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-top: 0px;
  }
`;
const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0px -24px;
  color: #fff5f5;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    flex-direction: column;
    margin: -12px 0px;
  }
`;
const DeviceContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding: 12px 0px;
  }
`;
const BtnContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 16px;
  }
`;
const BtnImage = styled.img`
  width: 56px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: 32px;
  }
`;
const BtnContentTextWrapper = styled.div`
  padding-left: 26px;
  font-weight: 700;
  font-size: 24px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-left: 16px;
    font-size: 16px;
  }
`;
const BtnContentDescription = styled.div`
  padding-top: 8px;
  font-size: 16px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 10px;
  }
`;

const Download: React.FC<{ scrollY: number; }> = ({ scrollY }) => {
  const isMobileWidth = useMediaQuery({
    query: `(${MEDIA_QUERY_WIDTH_MOBILE_WIDTH})`,
  });

  const width = useMemo(() => (isMobileWidth && 260) || 485, [isMobileWidth]);
  const height = useMemo(() => (isMobileWidth && 100) || 150, [isMobileWidth]);

  return (
    <StyledAppSection>
      <Content>
        <DeviceContent>
          <StartFire delay={0.8}>
            <Btn filled backdropBlur width={width} height={height} color="#ffffff14" rel="noreferrer" target="_blank" href={LINK_GOOGLE_PLAY_STORE}>
              <BtnContent>
                <BtnImage src={ImageDownloadPlaystore} />
                <BtnContentTextWrapper>
                  Download for Android
                  <BtnContentDescription>Get the Wallet mobile app from Google Play</BtnContentDescription>
                </BtnContentTextWrapper>
              </BtnContent>
            </Btn>
          </StartFire>
        </DeviceContent>
        <DeviceContent>
          <StartFire delay={1}>
            <Btn filled backdropBlur width={width} height={height} color="#ffffff14" rel="noreferrer" target="_blank" href={LINK_CHROME_WEB_STORE}>
              <BtnContent>
                <BtnImage src={ImageDownloadChrome} />
                <BtnContentTextWrapper>
                  Download for Chrome
                  <BtnContentDescription>Get the Wallet extension from the Chrome store</BtnContentDescription>
                </BtnContentTextWrapper>
              </BtnContent>
            </Btn>
          </StartFire>
        </DeviceContent>
      </Content>
    </StyledAppSection>
  );
};

export default Download;
