import { useState, useEffect } from 'react';

const useScroll = (ref: React.MutableRefObject<HTMLElement | null>) => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    if (!ref || !ref.current) {return;}

    ref.current.addEventListener('scroll', () => setScrollY((ref.current || {scrollTop: 0}).scrollTop));
  }, [ref]);

  return scrollY;
}

export default useScroll;
