import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import AppHeader from 'components/AppHeader';

const Title = styled.div`
  flex-grow: 1;
  padding-left: 40px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  user-select: none;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-left: 18px;
    font-size: 14px;
  }
`;

const Header: React.FC = () => (
  <AppHeader>
    <Title>Privacy Policy &amp; Terms of Service</Title>
  </AppHeader>
);

export default Header;
