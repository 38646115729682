import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE_EMOJIS } from 'configs/languages';

import resources from './resources';

let browserLanguage = navigator.language;
let storedLanguage = '';

if (browserLanguage !== 'ko') {
  browserLanguage = '';
}

storedLanguage = localStorage.getItem('language') || '';

if (Object.keys(resources).findIndex((language) => language === storedLanguage) < 0) {
  storedLanguage = '';
}

const currenctLanguage = storedLanguage || browserLanguage || 'en';

i18n.use(initReactI18next).init({
  resources,
  lng: currenctLanguage,
  fallbackLng: currenctLanguage,
  interpolation: {
    escapeValue: false
  }
});

export const LOCALES = Object.keys(resources).map(locale => ({
  value: locale,
  emoji: LANGUAGE_EMOJIS.find(emoji => emoji.key === locale)?.value
}));
