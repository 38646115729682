import React from 'react';
import styled from 'styled-components';
import transition from 'plugins/style/transition';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';

interface Props extends React.HTMLProps<HTMLAnchorElement> {
  children?: React.ReactNode;
  noneStyled?: boolean;
  outlined?: boolean;
  filled?: boolean;
  backdropBlur?: boolean;
  text?: boolean;
  icon?: boolean;
  disabled?: boolean;
  color?: string;
  hoverColor?: string;
  rel?: string;
  target?: string;
  href?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  radius?: number;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
};

const Root = styled.a<{
  noneStyled?: boolean;
  outlined?: boolean;
  filled?: boolean;
  backdropBlur?: boolean;
  text?: boolean;
  icon?: boolean;
  disabled?: boolean;
  radius?: number;
  hoverColor?: string;
}>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  ${transition}
  ${({ noneStyled }) => noneStyled ? '' : `
    height: 80px;
    min-width: 40px;
    padding-left: 20px;
    padding-right: 20px;
  `}
  ${({ outlined, filled, radius }) => outlined || filled ? `
    border-radius: ${radius || 20}px;
  ` : ''}
  ${({ outlined }) => outlined ? `
    border: solid 3px #fff;
  ` : ''}
  ${({ filled }) => filled ? `
    color: #fff;
    background-color: #000;
  ` : ''}
  ${({ text }) => text ? `
    border: none;
  ` : ''}
  ${({ icon }) => icon ? `
    width: 48px;
    height: 80px;
    min-height: unset;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 24px;
  ` : ''}
  ${({ backdropBlur }) => backdropBlur ? `
    backdrop-filter: blur(25px);
  ` : ''}
  ${({ hoverColor }) => hoverColor ? `
    &:hover {
      opacity: 1;
      background-color: ${hoverColor} !important;
    }
  ` : ''}
  ${({ disabled }) => disabled ? `
    pointer-events: none;
    opacity: 0.7;
  ` : ''}
  &:active {
    opacity: 0.5;
  }
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    ${({ outlined, filled }) => outlined || filled ? `
      border-radius: 12px;
    ` : ''}
  }
`;

const Btn: React.FC<Props> = ({
  children,
  noneStyled,
  outlined,
  filled,
  backdropBlur,
  text,
  icon,
  disabled,
  color,
  hoverColor,
  rel,
  target,
  href,
  width,
  height,
  fontSize,
  radius,
  onClick
}) => {
  const styledWidth = width && (typeof width === 'number' ? `${width}px` : '');
  const styledHeight = height && (typeof height === 'number' ? `${height}px` : '');
  const styledFontSize = fontSize && (typeof fontSize === 'number' ? `${fontSize}px` : '');
  const styledColor = outlined || filled ? '' : color;
  const styledBorderColor = outlined || filled ? color : '';
  const styledBackgroundColor = outlined || filled ? color : '';
  const rootStyle: React.CSSProperties = {};

  if (styledWidth) {rootStyle.width = styledWidth;}
  if (styledHeight) {rootStyle.height = styledHeight;}
  if (styledFontSize) {rootStyle.fontSize = styledFontSize;}
  if (styledColor) {rootStyle.color = styledColor;}
  if (styledBorderColor) {rootStyle.borderColor = styledBorderColor;}
  if (styledBackgroundColor) {rootStyle.backgroundColor = styledBackgroundColor;}

  return (
    <Root
      noneStyled={noneStyled}
      outlined={outlined}
      filled={filled}
      backdropBlur={backdropBlur}
      text={text}
      icon={icon}
      disabled={disabled}
      radius={radius}
      hoverColor={hoverColor}
      rel={rel}
      target={target}
      href={href}
      onClick={onClick}
      style={rootStyle}>
      { children }
    </Root>
  );
};

export default Btn;
