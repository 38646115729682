import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props {
  duration?: number;
  delay?: number;
};

const Root = styled.div<{
  show: boolean;
  duration: number;
}>`
  transition-duration: ${({duration}) => duration}s !important;
  ${({show}) => show ? `
    opacity: 1;
    transform: translateY(0px) scale(1);
  ` : `
    opacity: 0;
    transform: translateY(10px) scale(0.9);
  `}
`;

const StartFire: React.FC<Props> = ({ children, duration, delay }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    (delay && delay > 0)
      ? setTimeout(() => setShow(true), delay * 1000)
      : setShow(true);
  }, [delay])

  return (
    <Root show={show} duration={duration || 0.3}>{ children }</Root>
  );
};

export default StartFire;
