import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import i18n from 'i18next';
import styled from 'styled-components';
import { AppContainer } from 'styled-interfaces';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import { Header, Main } from './section-pnt';

const Root = styled(AppContainer)`
  padding-bottom: 300px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-bottom: 120px;
  }
`;

const Policy: React.FC = () => {
  const [contents, setContents] = useState('');

  useEffect(() => {
    const path = i18n.language === 'ko' ? '/markdown/ko/policy.md' : '/markdown/en/policy.md';

    fetch(path).then((response) => response.text()).then((text) => setContents(text));
  }, []);

  return (
    <Root>
      <Header />
      <Main />
      <ReactMarkdown children={contents} />
    </Root>
  );
};

export default Policy;
