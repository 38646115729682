import React from 'react';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import styled from 'styled-components';
import { LINK_HELPCENTER, LINK_GUIDE } from 'configs/link';
import AppHeader from 'components/AppHeader';
import Btn from 'components/Btn';

const ActionWrapper = styled.div<{ open?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0px -8px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    display: none;
  }
`;
const BtnWrapper = styled.div`
  padding: 0px 8px;
`;

const Header: React.FC = () => (
  <AppHeader>
    <ActionWrapper>
      <BtnWrapper>
        <Btn text height={45} rel="noreferrer" target="_blank" href={LINK_GUIDE}>Wallet Guide</Btn>
      </BtnWrapper>
      <BtnWrapper>
        <Btn text height={45} rel="noreferrer" target="_blank" href={LINK_HELPCENTER}>Help Center</Btn>
      </BtnWrapper>
    </ActionWrapper>
  </AppHeader>
);

export default Header;
