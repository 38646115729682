import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import AppContainer from './AppContainer';

export default styled(AppContainer)`
  display: flex;
  justify-content: space-between;
  height: 40px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    flex-direction: column;
    height: unset;
  }
`;
