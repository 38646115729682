import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import { AppSection, H1, Shadow, Text } from 'styled-interfaces';
import ScrollFire from 'components/ScrollFire';
import ImageOneScreen from 'assets/images/one-screen.png';
import ImageChainBifrost from 'assets/images/icon-bifrost.svg';
import ImageChainAvalanche from 'assets/images/icon-avalanche.svg';
import ImageChainBitcoin from 'assets/images/icon-bitcoin.svg';
import ImageChainKlaytn from 'assets/images/icon-klaytn.png';
import ImageChainEthereum from 'assets/images/icon-ethereum.svg';
import ImageChainPolygon from 'assets/images/icon-polygon.svg';
import ImageChainBSC from 'assets/images/icon-bsc.svg';
import { useMediaQuery } from 'react-responsive';

const Root = styled.div`
  position: relative;
  min-height: 900px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    min-height: unset;
  }
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 40px 0px;
  }
`;
const Image = styled.img`
  position: relative;
  width: 376px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: 80%;
    margin: 0px 10%;
  }
`;
const Content = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 500px;
  color: #fff5f5;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-right: 0px;
  }
`;
const Title = styled(H1)<{ opacity?: boolean; }>`
  margin: 0px;
  font-size: 60px;
  color: #fff;
  line-height: 60px;
  ${props => props.opacity ? 'opacity: 0.6;' : ''}
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 24px;
    line-height: 40px;
  }
`;
const Description = styled(Text)`
  margin: 0px;
  padding: 40px 0px 92px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-top: 20px;
    padding-bottom: 40px;
    font-size: 15px;
    line-height: 25px;
  }
`;
const ChainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: -15px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin: -8px;
  }
`;
const ChainWrapper = styled.div`
  width: calc(50% - 30px);
  padding: 15px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: calc(50% - 16px);
    padding: 8px;
  }
`;
const ChainContent = styled.div`
  display: flex;
  align-items: center;
`;
const ChainImageWrapper = styled.div`
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 2px;
  background-color: #fff;
  border-radius: 100px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: 28px;
    min-width: 28px;
    max-width: 28px;
    height: 28px;
    min-height: 28px;
    max-height: 28px;
  }
`;
const ChainImage = styled.img`
  width: 100%;
`;
const ChainTitle = styled.div`
  padding-left: 18px;
  font-size: 20px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-left: 12px;
    font-size: 16px;
  }
`;

const OneScreen: React.FC<{ scrollY: number; }> = ({ scrollY }) => {
  const isMobileWidth = useMediaQuery({
    query: `(${MEDIA_QUERY_WIDTH_MOBILE_WIDTH})`,
  });

  const chainList: {
    src: string;
    title: string;
  }[] = [{
    src: ImageChainBifrost,
    title: 'BIFROST'
  }, {
    src: ImageChainAvalanche,
    title: 'Avalanche'
  }, {
    src: ImageChainBitcoin,
    title: 'Bitcoin'
  }, {
    src: ImageChainKlaytn,
    title: 'Klaytn'
  }, {
    src: ImageChainEthereum,
    title: 'Ethereum'
  }, {
    src: ImageChainPolygon,
    title: 'Polygon'
  }, {
    src: ImageChainBSC,
    title: 'BSC'
  }];

  return (
    <AppSection>
      <Root>
        <Content>
          <ScrollFire scrollY={scrollY}>
            <Title>All Assets, on One Screen</Title>
          </ScrollFire>
          <ScrollFire scrollY={scrollY}>
            <Description>Without switching to different networks, view and manage your assets in one hand. Buy crypto with your credit card whenever and track transaction history with one tab. Scan the total amounts of your assets which are scattered across different networks.</Description>
          </ScrollFire>
          <ScrollFire scrollY={scrollY}>
            <ChainContainer>
              {chainList.map((chain, chainIndex) => (
                <ChainWrapper key={chainIndex}>
                  <ChainContent>
                      <ScrollFire scrollY={scrollY} delay={0.05 * chainIndex}>
                        <ChainImageWrapper>
                          <ChainImage src={chain.src} />
                        </ChainImageWrapper>
                      </ScrollFire>
                      <ChainTitle>
                        <ScrollFire scrollY={scrollY} delay={0.05 * chainIndex + 0.02}>{ chain.title }</ScrollFire>
                      </ChainTitle>
                  </ChainContent>
                </ChainWrapper>
              ))}
            </ChainContainer>
          </ScrollFire>
        </Content>
        <Background>
          <Shadow color="#e1a9007f" size={isMobileWidth ? 300 : 500} />
          <ScrollFire scrollY={scrollY}>
            <Image src={ImageOneScreen} />
          </ScrollFire>
        </Background>
      </Root>
    </AppSection>
  );
};

export default OneScreen;
