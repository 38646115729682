import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import transition from 'plugins/style/transition';

interface Props {
  scrollY: number;
  duration?: number;
  delay?: number;
};

const Root = styled.div<{
  show: boolean;
  duration: number;
  delay: number;
}>`
  opacity: 0;
  transform: translateY(10px) scale(0.9);
  ${props => props.show ? `
    opacity: 1;
    transform: translateY(0px) scale(1);
  ` : ''}
  ${transition}
  transition-delay: ${props => props.delay}s !important;
  transition-duration: ${props => props.duration}s !important;
`;

const ScrollFire: React.FC<Props> = ({ children, scrollY, duration, delay }) => {
  const rootRef = useRef(null as HTMLDivElement | null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const show = useMemo(() => (rootRef?.current?.getBoundingClientRect()?.top || 0) < document.body.scrollHeight, [scrollY]);

  return (
    <Root show={show} ref={rootRef} duration={duration || 0.3} delay={delay || 0}>{ children }</Root>
  );
};

export default ScrollFire;
