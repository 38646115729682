import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH, MEDIA_QUERY_WIDTH_LAPTOP_WIDTH } from 'configs/mediaQuery';
import { LINK_CHROME_WEB_STORE, LINK_GOOGLE_PLAY_STORE } from 'configs/link';
import { AppSection, H1, H2, Shadow } from 'styled-interfaces';
import StartFire from 'components/StartFire';
import Btn from 'components/Btn';
import ImageMain from 'assets/images/main.png';
import ImageDownloadPlaystore from 'assets/images/download-playstore.svg';
import ImageDownloadChrome from 'assets/images/download-chrome.svg';

const StyledAppSection = styled(AppSection)`
  margin-top: 0px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
  }
`;
const Background = styled.div`
  position: absolute;
  top: -70px;
  left: -50px;
  right: 0;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    top: -50px;
    left: -40px;
    right: unset;
  }
`;
const Image = styled.img`
  position: relative;
  height: 687px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: 814px;
    height: unset;
  }
`;
const Content = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 32%;
  color: #fff;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-left: 0;
  }
`;
const Title = styled(H1)`
  margin: 0px;
  font-size: 80px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 28px;
    line-height: 40px;
  }
`;
const Subtitle = styled(H2)`
  margin: 0px;
  padding: 20px 0px 52px;
  color: #fff;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding: 12px 0px 40px;
    font-size: 18px;
    line-height: unset;
  }
`;
const DownloadContainer = styled.div`
  position: relative;
  display: flex;
  margin: 0px -20px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    flex-direction: column;
    margin: -12px 0px;
  }
`;
const DownloadContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding: 12px 0px;
  }
`;
const BtnContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 16px;
  }
`;
const BtnImage = styled.img`
  width: 56px;
  @media (${MEDIA_QUERY_WIDTH_LAPTOP_WIDTH}) {
    width: 32px;
  }
`;
const BtnContentTextWrapper = styled.div`
  padding-left: 24px;
  font-weight: 700;
  font-size: 24px;
  @media (${MEDIA_QUERY_WIDTH_LAPTOP_WIDTH}) {
    padding-left: 16px;
    font-size: 16px;
  }
`;
const BtnContentDescription = styled.div`
  padding-top: 12px;
  font-size: 16px;
  font-weight: 400;
  @media (${MEDIA_QUERY_WIDTH_LAPTOP_WIDTH}) {
    padding-top: 6px;
    font-size: 12px;
  }
`;

const Main: React.FC = () => {
  const isLaptopWidth = useMediaQuery({
    query: `(${MEDIA_QUERY_WIDTH_LAPTOP_WIDTH})`,
  });

  const width = useMemo(() => (isLaptopWidth && 260) || 344, [isLaptopWidth]);
  const height = useMemo(() => (isLaptopWidth && 80) || 140, [isLaptopWidth]);

  return (
    <StyledAppSection>
      <Background>
        <StartFire delay={0.2}>
          <Image src={ImageMain} />
        </StartFire>
      </Background>
      <Content>
        <Shadow />
        <StartFire delay={0.5}>
          <Title>Discover control</Title>
        </StartFire>
        <StartFire delay={0.6}>
          <Subtitle>Manage all of your assets in one place, no matter where you are.</Subtitle>
        </StartFire>
        <DownloadContainer>
          <DownloadContent>
            <StartFire delay={0.8}>
              <Btn filled backdropBlur width={width} height={height} color="#ffffff14" hoverColor="#379EF3" rel="noreferrer" target="_blank" href={LINK_GOOGLE_PLAY_STORE}>
                <BtnContent>
                  <BtnImage src={ImageDownloadPlaystore} />
                  <BtnContentTextWrapper>
                    Download for Android
                    <BtnContentDescription>Get the Wallet mobile app from Google Play</BtnContentDescription>
                  </BtnContentTextWrapper>
                </BtnContent>
              </Btn>
            </StartFire>
          </DownloadContent>
          <DownloadContent>
            <StartFire delay={1}>
              <Btn filled backdropBlur width={width} height={height} color="#ffffff14" hoverColor="#379EF3" rel="noreferrer" target="_blank" href={LINK_CHROME_WEB_STORE}>
                <BtnContent>
                  <BtnImage src={ImageDownloadChrome} />
                  <BtnContentTextWrapper>
                    Download for Chrome
                    <BtnContentDescription>Get the Wallet extension from the Chrome store</BtnContentDescription>
                  </BtnContentTextWrapper>
                </BtnContent>
              </Btn>
            </StartFire>
          </DownloadContent>
        </DownloadContainer>
      </Content>
    </StyledAppSection>
  );
};

export default Main;
