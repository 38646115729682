import transition from './transition';

const buttonOpacityTransition = `
  cursor: pointer;
  ${transition}
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`;

export default buttonOpacityTransition;
