import 'es6-promise/auto';
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import './plugins/reactGA';
import './plugins/screenSizer';

import './assets/styles/default.css';
import App from './App';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
