import React, { useRef } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import './i18n';
import useScroll from 'plugins/useScroll';
import { Home, Policy, Terms, Announcement, AnnouncementKo } from 'pages';
import AppFooter from 'components/AppFooter';

const Root = styled.div`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
`;
const Background = styled.div`
  position: relative;
  overflow: hidden;
  & > * {
    position: relative;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 6758px;
    background: linear-gradient(
      180deg,
      #1C1F27 0%,
      #1C1F27 14%,
      #243154 18.15%,
      #24142C 34.94%,
      #142021 42.15%,
      #000000 51.29%,
      #11052B 72.67%,
      #020B06 99.93%,
      #1C1F27 100%
    );
  }
`;

const App: React.FC = () => {
  const rootRef = useRef(null as HTMLDivElement | null);
  const scrollY = useScroll(rootRef);

  return (
    <Root ref={rootRef}>
      <Background>
        <HashRouter>
          <Switch>
            <Route path="/announcementKo" children={<AnnouncementKo />} />
            <Route path="/announcement" children={<Announcement />} />
            <Route path="/pnt/policy" children={<Policy />} />
            <Route path="/pnt/terms" children={<Terms />} />
            <Route path="/pnt">
              <Redirect to="/pnt/policy" />
            </Route>
            <Route path="/pnt/*">
              <Redirect to="/pnt/policy" />
            </Route>
            <Route path="/*" children={<Home scrollY={scrollY} />} />
            <Route path="*" children={<Home scrollY={scrollY} />} />
          </Switch>

          <AppFooter />
        </HashRouter>
      </Background>
    </Root>
  );
};

export default App;
