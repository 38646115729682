import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH, MEDIA_QUERY_HEIGHT_THIN } from 'configs/mediaQuery';

export default styled.div`
  position: relative;
  height: 197px;
  z-index: 3;
  @media (${MEDIA_QUERY_HEIGHT_THIN}) {
    height: 130px;
  }
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    height: 100px;
    margin-left: -12px;
    margin-right: -12px;
  }
`;
