import React from 'react';
import styled from 'styled-components';
import {
  AppFooter as StyledAppFooter,
  AppFooterContent as StyledAppFooterContent
} from 'styled-interfaces';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import buttonOpacityTransition from 'plugins/style/buttonOpacityTransition';
import { LINK_GUIDE, LINK_LINKTREE } from 'configs/link';
import { Link } from 'react-router-dom';

const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100% - 1px);
  border-top: 1px solid #353632;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    flex-direction: column;
    align-items: flex-start;
    height: unset;
  }
`;
const ButtonWrapper = styled.div<{ right?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: -8px;
  margin-right: -8px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    flex-direction: column;
    align-items: flex-start;
    margin: -8px;
    margin-bottom: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    ${props => props.right ? 'text-align: right;' : ''}
  }
`;
const Button = styled.a<{
  square?: boolean;
  link?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  ${props => props.square ? `
    width: 20px;
    height: 20px;
  ` : ''}
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  ${props => props.link ? `
    font-weight: 300;
    font-size: 14px;
    color: #379EF3;
  ` : ''}
  cursor: pointer;
  user-select: none;
  ${buttonOpacityTransition}
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding: 8px;
    font-size: 12px;
  }
`;
const Spacer = styled.div`
  flex-grow: 1;
`;
const CopyRight = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  font-size: 15px;
  text-align: right;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 12px;
  }
`;

const AppFooter: React.FC = () => (
  <StyledAppFooter>
    <StyledAppFooterContent>
      <Contents>
        <ButtonWrapper>
          <Button rel="noreferrer" target="_blank" href={LINK_LINKTREE}>Linktree</Button>
          <Button rel="noreferrer" target="_blank" href={LINK_GUIDE}>Wallet Guide</Button>
        </ButtonWrapper>
        <Spacer />
        <ButtonWrapper>
          <Link to="/pnt/policy">
            <Button link>Privacy Policy</Button>
          </Link>
          <Link to="/pnt/terms">
            <Button link>Terms of Service</Button>
          </Link>
          <CopyRight>© {new Date().getFullYear()} PiLab Technology</CopyRight>
        </ButtonWrapper>
      </Contents>
    </StyledAppFooterContent>
  </StyledAppFooter>
);

export default AppFooter;
