import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import logo from 'assets/images/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import buttonOpacityTransition from 'plugins/style/buttonOpacityTransition';

interface Props {
  small?: boolean;
};

const Root = styled(Link)<{ current?: boolean; }>`
  display: block;
  overflow: hidden;
  user-select: none;
  ${props => props.current ? `
    cursor: default;
    pointer-events: none;
  ` : `
    cursor: pointer;
    ${buttonOpacityTransition}
  `}
`;
const Image = styled.img<{ small?: boolean }>`
  ${props => props.small ? `
    width: 35px;
    height: 31px;
  ` : `
    width: 49px;
    height: 43px;
  `}
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: 32px;
    height: 28px;
  }
`;

const AppLogo: React.FC<Props> = ({ small }) => {
  const location = useLocation();

  return (
    <Root to="/" current={location.pathname === '/'}>
      <Image small={small} src={logo} />
    </Root>
  )
};

export default AppLogo;
