import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import { AppSection, H1, Text } from 'styled-interfaces';
import ScrollFire from 'components/ScrollFire';
import ImageNFT from 'assets/images/nft.png';

const Root = styled.div`
  position: relative;
  min-height: 900px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    display: flex;
    flex-direction: column-reverse;
    min-height: unset;
  }
`;
const Background = styled.div`
  position: absolute;
  top: -232px;
  right: -92px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    position: relative;
    top: unset;
    right: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -20%;
    margin-left: -40%;
  }
`;
const Image = styled.img`
  position: relative;
  width: 1007px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: 100%;
  }
`;
const Content = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 500px;
  color: #fff5f5;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-right: 0px;
  }
`;
const Title = styled(H1)<{ opacity?: boolean; }>`
  margin: 0px;
  font-size: 60px;
  color: #fff;
  line-height: 90px;
  ${props => props.opacity ? 'opacity: 0.6;' : ''}
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 24px;
    line-height: 40px;
  }
`;
const Description = styled(Text)`
  margin: 0px;
  padding: 40px 0px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding-top: 20px;
    padding-bottom: 40px;
    font-size: 15px;
    line-height: 25px;
  }
`;

const NFT: React.FC<{ scrollY: number; }> = ({ scrollY }) => {
  return (
    <AppSection>
      <Root>
        <Background>
          <Image src={ImageNFT} />
        </Background>
        <Content>
          <ScrollFire scrollY={scrollY}>
            <Title>Send and Store NFTs, regardless of the network</Title>
          </ScrollFire>
          <ScrollFire scrollY={scrollY}>
            <Description>Start your NFTs collection with Biport. Send, receive, and store your collections in one wallet. Biport supports multichain NFTs which the users are able to store, send, and receive all NFTs on Avalanche, Bifrost Network, Bitcoin, BNB Chain, Ethereum, and Polygon network.</Description>
          </ScrollFire>
        </Content>
      </Root>
    </AppSection>
  );
};

export default NFT;
