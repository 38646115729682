import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
const Title = styled.h1`
  width: 100%;
  text-align: center;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
`;
const ContentContainer = styled.div`
  padding: 16px 8px;
  flex-grow: 1;
  overflow-y: scroll;
`;
const SubContent = styled.div`
  padding-left: 20px;
`;

const AnnouncementKo: React.FC = () => (
  <Container>
    <Title>업데이트 공지</Title>
    <Divider />
    <ContentContainer>
      <div>- 0.1.14 버전이 출시되었습니다.</div>
      <SubContent>
        : 토큰을 검색할 때 마다 해당 토큰(aKAI)이 목록에 누적되어 추가되는 현상 수정
      </SubContent>
      <div>
        '구글 플레이 스토어'에서 biport wallet을 검색하시고 '업데이트'를
        클릭하세요.
      </div>
      <div>이용해주셔서 대단히 감사드립니다.</div>
    </ContentContainer>
  </Container>
);

export default AnnouncementKo;
