import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';

import AppContainer from './AppContainer';

export default styled(AppContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    justify-content: center;
  }
`;
