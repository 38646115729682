import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Btn from 'components/Btn';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10px;
  padding: 50px 0px 100px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    display: none;
  }
`;
const BtnWrapper = styled(Link)`
  padding: 0px 10px;
`;
const BtnContent = styled.div`
  font-weight: 700;
  font-size: 24px;
`;

const Main: React.FC = () => {
  const location = useLocation();

  return (
    <Root>
      <BtnWrapper to="/pnt/policy">
        <Btn filled radius={12} height={56} color={location.pathname === '/pnt/policy' ? '#379EF3' : '#ffffff19'}>
          <BtnContent>Privacy Policy</BtnContent>
        </Btn>
      </BtnWrapper>
      <BtnWrapper to="/pnt/terms">
        <Btn filled radius={12} height={56} color={location.pathname === '/pnt/terms' ? '#379EF3' : '#ffffff19'}>
          <BtnContent>Terms of Service</BtnContent>
        </Btn>
      </BtnWrapper>
    </Root>
  )
};

export default Main;
