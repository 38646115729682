import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import { AppSection, H1, Shadow, Text } from 'styled-interfaces';
import { APP_CONTAINER_MARGIN, APP_CONTAINER_MARGIN_MOBILE } from 'styled-interfaces/AppContainer';
import ScrollFire from 'components/ScrollFire';
import ImageManagement from 'assets/images/management.png';
import ImageDappCompound from 'assets/images/icon-compound.svg';
import ImageDappVenus from 'assets/images/icon-venus.svg';
import ImageDappOwly from 'assets/images/icon-owly.svg';
import ImageDappBenqi from 'assets/images/icon-benqi.svg';
import ImageDappBifi from 'assets/images/icon-bifi.svg';
import ImageDappAave from 'assets/images/icon-aave.svg';

const StyledAppSection = styled(AppSection)`
  margin-bottom: -50px;
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    flex-direction: column-reverse;
  }
`;
const ImageWrapper = styled.div`
  position: relative;
  width: calc(18% + ${APP_CONTAINER_MARGIN * 2}px);
  margin-left: -${APP_CONTAINER_MARGIN}px;
  padding: 140px 12% 0px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: calc(60% + ${APP_CONTAINER_MARGIN_MOBILE * 2}px);
    margin: 0px -${APP_CONTAINER_MARGIN_MOBILE}px;
    padding: 60px 20% 0px;
  }
`;
const Image = styled.img`
  position: relative;
  width: 100%;
`;
const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 52%;
  padding-top: 140px;
  color: #fff5f5;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: 100%;
  }
`;
const Title = styled(H1)<{ opacity?: boolean; }>`
  margin: 0px;
  color: #fff;
  font-size: 60px;
  ${props => props.opacity ? 'opacity: 0.6;;' : ''}
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 30px;
    line-height: 43px;
  }
`;
const Description = styled(Text)`
  margin: 0px;
  padding: 40px 0px 50px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding: 30px 0px;
    font-size: 15px;
    line-height: 22px;
  }
`;
const DappContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin: -6px;
  }
`;
const DappWrapper = styled.div`
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  padding: 12px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: 36px;
    min-width: 36px;
    max-width: 36px;
    height: 36px;
    min-height: 36px;
    max-height: 36px;
    padding: 6px;
  }
`;
const DappImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Management: React.FC<{ scrollY: number; }> = ({ scrollY }) => {
  const dapps = [
    ImageDappCompound,
    ImageDappVenus,
    ImageDappOwly,
    ImageDappBenqi,
    ImageDappBifi,
    ImageDappAave
  ];

  return (
    <StyledAppSection>
      <Shadow color="#e1a90066" size={500} />
      <ContentWrapper>
        <ImageWrapper>
          <ScrollFire scrollY={scrollY}>
            <Image src={ImageManagement} />
          </ScrollFire>
        </ImageWrapper>
        <Content>
          <ScrollFire scrollY={scrollY}>
            <Title>Intuitive Dashboard</Title>
          </ScrollFire>
          <ScrollFire scrollY={scrollY}>
            <Description>Store, learn, and manage your DeFi positions. You can explore recommended protocols and inform about the top DeFi services. Make smarter decisions for your next investment and update DeFi positions on your way.</Description>
          </ScrollFire>
          <DappContainer>
            {dapps.map((dapp, dappIndex) => (
              <DappWrapper key={dappIndex}>
                <ScrollFire scrollY={scrollY} delay={0.05 * dappIndex}>
                  <DappImage src={dapp} />
                </ScrollFire>
              </DappWrapper>
            ))}
          </DappContainer>
        </Content>
      </ContentWrapper>
    </StyledAppSection>
  );
};

export default Management;
