import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import styled from 'styled-components';

import AppContainer from './AppContainer';

export default styled(AppContainer)`
  max-width: 1440px;
  margin: 100px auto;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin: 40px auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
