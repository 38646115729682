import React from 'react';
import {
  AppHeader as StyledAppHeader,
  AppHeaderContent as StyledAppHeaderContent
} from 'styled-interfaces';
import AppLogo from './AppLogo';

const AppHeader: React.FC = ({ children }) => {
  return (
    <StyledAppHeader>
      <StyledAppHeaderContent>
        <AppLogo />
        {children}
      </StyledAppHeaderContent>
    </StyledAppHeader>
  );
};

export default AppHeader;
