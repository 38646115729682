import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { MEDIA_QUERY_WIDTH_MOBILE_WIDTH } from 'configs/mediaQuery';
import { AppSection, H1 } from 'styled-interfaces';
import Btn from 'components/Btn';
import { LINK_GOOGLE_PLAY_STORE } from 'configs/link';
import { APP_CONTAINER_MARGIN_MOBILE } from 'styled-interfaces/AppContainer';
import ScrollFire from 'components/ScrollFire';
import ImageWrapUp from 'assets/images/wrap-up.png';
import logo from 'assets/images/logo.svg';

const StyledAppSection = styled(AppSection)`
  overflow: hidden;
  margin-bottom: 0px;
  padding-bottom: 200px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    margin-left: -${APP_CONTAINER_MARGIN_MOBILE}px;
    margin-right: -${APP_CONTAINER_MARGIN_MOBILE}px;
    padding-top: 0px;
    padding-bottom: 100px;
    padding-left: ${APP_CONTAINER_MARGIN_MOBILE}px;
    padding-right: ${APP_CONTAINER_MARGIN_MOBILE}px;
  }
`;
const ContentWrapper = styled.div`
  height: 900px;
  margin-bottom: -200px;
`;
const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    bottom: 0;
    left: calc(50% - 600px);
    right: unset;
    width: 1200px;
    height: 795px;
  }
`;
const Image = styled.img`
  position: relative;
  width: 100%;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    width: unset;
    height: 100%;
    max-width: 100%;
  }
`;
const Content = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  color: #fff5f5;
`;
const LogoImage = styled.img`
  height: 150px;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    height: 100px;
  }
`;
const Title = styled(H1)`
  margin: 0px;
  padding: 100px 0px;
  color: #fff;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    padding: 70px 0px;
    font-size: 28px;
    line-height: 40px;
  }
`;
const BtnContent = styled.div`
  font-weight: 700;
  font-size: 24px;
  white-space: nowrap;
  @media (${MEDIA_QUERY_WIDTH_MOBILE_WIDTH}) {
    font-size: 14px;
  }
`;

const WrapUp: React.FC<{ scrollY: number; }> = ({ scrollY }) => {
  const isMobileWidth = useMediaQuery({
    query: `(${MEDIA_QUERY_WIDTH_MOBILE_WIDTH})`,
  });

  const width = useMemo(() => (isMobileWidth && 200) || 400, [isMobileWidth]);
  const height = useMemo(() => (isMobileWidth && 42) || 76, [isMobileWidth]);

  return (
    <StyledAppSection>
      <ContentWrapper>
        <Background>
          <ScrollFire scrollY={scrollY}>
            <Image src={ImageWrapUp} />
          </ScrollFire>
        </Background>
        <Content>
          <ScrollFire scrollY={scrollY}>
            <LogoImage src={logo} />
          </ScrollFire>
          <ScrollFire scrollY={scrollY}>
            <Title>All in One Wallet</Title>
          </ScrollFire>
          <ScrollFire scrollY={scrollY}>
            <Btn filled backdropBlur width={width} height={height} color="#ffffff14" hoverColor="#379EF3" rel="noreferrer" target="_blank" href={LINK_GOOGLE_PLAY_STORE}>
              <BtnContent>Download Biport Mobile</BtnContent>
            </Btn>
          </ScrollFire>
        </Content>
      </ContentWrapper>
    </StyledAppSection>
  );
};

export default WrapUp;
