const resource = {
  translation: {
    common: {
      link: {
        install: 'https://docs.bifi.finance/biport-guide/getting-started/install-biport-wallet',
        guide: 'https://docs.bifi.finance/biport-guide/',
        telegram: {
          community: 'https://t.me/Bifrost_Global/',
          notice: 'https://t.me/Bifrost_Global/'
        },
        banner: 'https://forms.gle/jBQWEWEqPeM6wk68A'
      }
    },
    page: {
      home: {
        main: {
          subtitle: 'Your Decentralized Passport',
          title: 'Biport',
          description: 'Start your blockchain journey with Biport Multichain Wallet\nfor Bitcoin, Ethereum, BSC, and beyond',
          button: 'Add to Chrome'
        },
        gate: {
          title: {
            prepand: 'Your decentralized journey\nstarts with',
            append: ''
          },
          shiftItems: [
            'a Wallet.',
            'Biport.'
          ],
          description: 'A wallet in the decentralized world works a lot like a passport.\nTo enter, you need a wallet and some crypto.\nBiport Wallet will allow you to connect to a suite of DApps and DeFi, NFTs,\nand your friends and family — all in one wallet.'
        },
        stickyScroll: {
          subtitle: 'An elevated DeFi experience.',
          title: 'One Wallet, All Chains',
          items: [
            'Send, receive, and store crypto.\nView transactions for borrowing, lending, and staking assets\n— all in one place.',
            'All assets in one screen regardless of the network.\nNo more switching networks all the time.',
            'Safer than your centralized wallet.\nYou have complete control of your assets.'
          ]
        },
        learnMore: {
          description: 'Founders of True Multichain Interoperability',
          title: 'Built by Blockchain Experts of BIFROST',
          button: 'Learn More'
        },
        faq: {
          items: [{
            question: 'What is Biport Wallet?',
            answer: 'Biport Wallet is a decentralized multichain wallet that allows users to view and manage cryptocurrencies scattered across different blockchain networks in one place. You can also access all DApps that support MetaMask.'
          }, {
            question: 'What is the difference between decentralized and centralized wallets?',
            answer: 'To put it simply, the custody of your assets. In decentralized wallets, only you have access and control to move your assets. On the other hand, in centralized wallets, you entrust your private keys and assets to a third-party custodian (e.g. an exchange).'
          }, {
            question: 'Which tokens are supported on Biport?',
            answer: 'Biport initially supports Bitcoin, Ethereum, Binance Smart Chain (BSC), and Avalanche networks — meaning you can store Bitcoin and a wide range of ERC20, BEP20, and C-Chain tokens. More integration of networks and its respective tokens will be supported soon.'
          }, {
            question: 'Which DApps can I use on Biport?',
            answer: 'Biport is best optimized for <1 href="https://bifi.finance/">BiFi</1>, the first decentralized lending protocol to offer native BTC lending. Users can deposit assets to BiFi lending directly within the wallet or connect Biport on <1 href="http://app.bifi.finance">app.bifi.finance</1>. More advanced and DeFi-specific features will be updated soon.'
          }, {
            question: 'Are there any fees?',
            answer: 'There are no fees for using Biport. Just pay the on-chain network fees for each transaction!'
          }],
          learnMore: 'Learn More',
          title: 'Do It All With Biport Wallet',
          subtitle: 'Wallet for everyone, everywhere',
          button: 'Your Journey Starts Here'
        }
      }
    }
  }
};

export default resource;
