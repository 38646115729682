import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
const Title = styled.h1`
  width: 100%;
  text-align: center;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
`;
const ContentContainer = styled.div`
  padding: 16px 8px;
  flex-grow: 1;
  overflow-y: scroll;
`;
const SubContent = styled.div`
  padding-left: 20px;
`;

const Announcement: React.FC = () => (
  <Container>
    <Title>Update Notice</Title>
    <Divider />
    <ContentContainer>
      <div>- Version 0.1.14 has been released.</div>
      <SubContent>
        : Fixed the phenomenon that the corresponding token (aKAI) is accumulated and added to the list whenever a token is searched
      </SubContent>
      <div>
        Search for biport wallet in 'Google Play Store' and 'update' it. Thank
        you.
      </div>
    </ContentContainer>
  </Container>
);

export default Announcement;
